export default {
  "language": "UA",
  "m_campaign": "Кампанія",
  "m_home": "Головна",
  "m_compare": "Порівняти",
  "m_challenges": "Випробування",
  "m_event": "Подія",
  "m_events": "Події",
  "m_options": "Налаштування",
  "m_charts": "Графік",
  "m_about": "Про нас",
  "m_vChanges": "Зміни {version}",
  "m_library": "Бібліотека",
  "m_addLibrary": "Додати в бібліотеку",
  "m_copy": "Копіювати",
  "m_sharableLink": "Загальнодоступне посилання",
  "m_listCars": "Список авто",
  "m_type": "Тип",
  "m_search": "Пошук",
  "m_searchCar": "Пошук авто",
  "m_searchTracks": "Пошук трас",
  "m_selectTrack": "Обрати трасу",
  "m_contributors": "Автори",
  "m_printBy": "надруковано",
  "m_display": "Показати",
  "m_colors": "Кольори",
  "m_more3dot": "Ще...",
  "m_trackset": "Набір трас",
  "m_clear": "Очистити",
  "m_clearCars": "Очистити авто",
  "m_load": "Завантажити",
  "m_loadChart": "Завантажити графік",
  "m_memory": "Слот пам'яті",
  "m_image": "Зображення",
  "m_pleaseWait3dot": "Зачекайте будь ласка...",
  "m_downloadPng": "Скачати PNG",
  "m_save": "Зберегти",
  "m_cancel": "Відмінити",
  "m_login": "Увійти",
  "m_gotoLogin": "Повернутись до входу",
  "m_email": "Пошта",
  "m_emailVerification": "Підтвердження пошти",
  "m_username": "Ім'я користувача",
  "m_password": "Пароль",
  "m_newPassword": "Новий пароль",
  "m_repeatPassword": "Повторіть пароль",
  "m_repeat": "Повторіть",
  "m_charLeast": "мінімум {n} символів",
  "m_register": "Реєстрація",
  "m_forgot": "Забули",
  "m_allmostDone": "Майже готово",
  "m_here": "Тут",
  "m_filter": "Фільтр | Фільтри",
  "m_change": "Змінити",
  "m_analyze": "Аналізувати",
  "m_submitReview": "Відправити на перевірку",
  "m_saveToLibrary": "Зберегти в бібліотеку",
  "m_delete": "Видалити",
  "m_approve": "Схвалити",
  "m_round": "Раунд | Раунди",
  "m_requirements": "Вимоги",
  "m_empty": "Пустий",
  "m_emptyRound": "Пустий раунд",
  "m_unlockRound": "Розблокувати цей раунд",
  "m_lockRound": "Заблокувати цей раунд для мене",
  "m_findBest": "Знайти найкращих",
  "m_other": "Інше",
  "m_win": "успіх",
  "m_lose": "провал",
  "m_draw": "нічия",
  "m_notime": "немає даних",
  "m_select": "обрати",
  "m_resetSolutions": "Скинути тільки рішення",
  "m_resetRound": "Скинути раунд",
  "m_includeDownvote": "Включати результати з негативними відгуками?",
  "m_showDataFromPast": "Показати дані з попередніх версій",
  "m_showOldTags": "Показати застарілі мітки",
  "m_challengeName": "Назва випробування",
  "m_eventName": "Назва події",
  "m_numberRounds": "Кількість раундів",
  "m_yes": "Так",
  "m_no": "Ні",
  "m_new": "Новий",
  "m_templateGuide": "Керівництво з шаблонів",
  "m_createNewCg": "Створити нове випробування",
  "m_createNewEvent": "Створити нову подію",
  "m_title": "Назва",
  "m_twisty": "Звивисті",
  "m_drag": "Траси",
  "m_city": "Вулиці",
  "m_rqLimit": "Ліміт RQ",
  "m_points": "Очки",
  "m_time": "Час",
  "m_uploadPrint": "Завантажити знімок",
  "m_done": "Готово",
  "m_sort": "Сортувати",
  "m_send": "Відправити",
  "m_backHome": "Назад на головну",
  "m_any": "Будь-який",
  "m_rarity": "Рідкість",
  "m_car": "Авто | Авто",
  "m_changed": "Змінено",
  "m_noRecords": "Немає записів",
  "m_showOtherTracks": "Показати інші траси",
  "m_showMore": "Показати більше",
  "m_lastContribution": "Останні автори",
  "m_nothingFound": "Нічого не знайдено",
  "m_changedCars": "змінені авто",
  "m_uploadSuccess": "Завантажено успішно",
  "m_noData": "Немає даних для відображення",
  "m_noTimesFound": "Результати не знайдені",
  "m_logoutSuccess": "Вихід успішний",
  "m_loginSuccess": "Ви увійшли",
  "m_deleteSuccess": "Успішно видалено",
  "m_saveSuccess": "Успішно збережено",
  "m_approveSuccess": "Успішно схвалено",
  "m_sentReview": "Відправлено на перевірку",
  "m_language": "Мова",
  "p_userSentCgForAnalyse": "Дякую! Ваш раунд буде проаналізовано. Приєднуйтесь до нашого Discord серверу!",
  "p_emptyRoundForUser": "Цей раунд ще не готовий. Ви можете нам допомогти створити його і відправити на перевірку.",
  "p_emptyRound2": "Цей раунд ще не готовий. Якщо Ви зараз на цьому раунді, Ви можете нам допомогти включити його.",
  "p_emptyRoundLogin": "Увійдіть, щоб почати створювати цей раунд або зв'яжіться з модераторами на нашому Discord сервері:",
  "p_lockRoundPhrase": "Заблокувати цей раунд для Вас щоб розпочати робити його",
  "p_modDoingRound": "{mod} робить цей раунд",
  "p_patronsOnly": "Ця функція доступна тільки для патреонів (Рівень {tier}).",
  "p_bestOfDescription": "Повертає список найкращих авто для даної траси і фільтру. Наприклад:",
  "p_chartsDescription": "Додає всі результати для траси на графік. Для демонстрації:",
  "p_templateWarning": "Переконайтесь що Ваш шаблон може бути корисним для спільноти, інакше він не буде схвалений.",
  "p_about1": "Цей проект зроблений TiagoXavi і він не пов'язаний з Hutch Games Ltd.",
  "p_about2": "Зіткнулись з проблемою або є пропозиція?\nПриєднуйтесь до Discord серверу або відправте лист (mighty.boy@topdrivesrecords.com)",
  "p_about3": "TDR абсолютно безкоштовний. Ви можете підтримати проект щоб він продовжував бути онлайн і отримувати нові покращення.",
  "p_resetPassHelper": "Введіть свою пошту для отримання посилання на скидання паролю.",
  "p_resetPassIfExist": "Якщо користувач з такою поштою існує, посилання на скидання паролю було відправлено.",
  "p_confirmationWillSent": "Буде відправлено лист для підтвердження",
  "p_checkYourEmailBox": "Будь ласка, перевірте свою пошту",
  "p_spamToo": "Папку спаму також!",
  "p_somethingWrong": "Щось пішло не так",
  "g_joinDiscord": "Приєднуйтесь до TDR Discord серверу, щоб обговорити покращення Вашого шаблону або чому Ваш шаблон було відхилено.",
  "m_timeToBeat": "Час суперника",
  "g_rule0": "Ваш шаблон повинен мати контекст для авто, який має сенс.  Наприклад, однакова мітка, однакова країна, однакові шины, одинаковий виробник, однаковий клас... Або ж контекст, який роблять Hutch як вимоги для подій/випробувань.",
  "g_rule1": "Ви повинні додати всі авто, доступні для контексту або хоча б спробувати додати всі. Додавайте не тільки ті авто, які є у Вас.",
  "g_rule2": "Шаблон повинен порівнювати авто зі схожими характеристиками. Немає сенсу порівнювати задньопривідне авто на динаміці з повнопривідними всесезонними авто на трасах із сухим асфальтом.",
  "g_rule3": "Змішуйте траси по асфальту з трасами по бездоріжжю тільки тоді, коли в цьому є сенс.",
  "g_rule4": "Не додавайте всі можливі траси, доступні у грі. Додаток буде мати проблеми з продуктивністю.",
  "g_rule5": "Старайтесь сортувати траси за типом, наприклад, групуючи прямі траси та траси з однаковим покриттям.",
  "g_rule6": "Старайтесь сортувати авто за RQ (у порядку спадання) або по продуктивністі на обраних трасах.",
  "g_rule7": "Шаблон не обов'язково повинен мати заповненим кожен результат, проте Ви можете заповнити все що можете перед відправленням.",
  "g_rule8": "Використовуйте наглядну назву.",
  "g_rule9": "Перевірте, чи вже не існує шаблону з такою ж метою, як у Вас.",
  "m_checkOut": "Погляньте на",
  "s_dry": "Сухо",
  "s_aspht": "Асф.",
  "s_wet": "Дощ",
  "s_gravel": "Гравй",
  "s_ice": "Лід",
  "s_sand": "Пісок",
  "s_snow": "Сніг",
  "s_grass": "Трава",
  "s_dirt": "Грунт",
  "c_topSpeed": "Швидк.",
  "c_handling": "Управління",
  "c_hand": "@:c_handling | @:c_handling",
  "c_class": "Клас | Класи",
  "c_year": "Рік | Роки",
  "c_tyre": "Шина | Шини",
  "c_drive": "Привід | Приводи",
  "c_clearance": "Дорожній просвіт | Дорожні просвіти",
  "c_country": "Країна | Країни",
  "c_brand": "Виробник | Виробники",
  "c_tag": "Мітка | Мітки",
  "c_stock": "Початковий",
  "c_weight": "Вага",
  "c_fuel": "Пальне",
  "c_seats": "Кількість місць",
  "c_enginePos": "Положення двигуна",
  "c_bodyStyle": "Стиль кузову",
  "c_prizeCar": "Призова тачка",
  "c_prize cars": "Призові тачки",
  "c_non-prize cars": "Непризові тачки",
  "c_mid": "Середній",
  "c_low": "Низький",
  "c_high": "Високий",
  "c_performance": "Динаміка",
  "c_standard": "Стандартні",
  "c_all-surface": "Всесезонні",
  "c_off-road": "Позашляхові",
  "c_slick": "Гоночні",
  "c_saloon": "Седан",
  "c_roadster": "Родстер",
  "c_coupe": "Купе",
  "c_hatchback": "Хетчбек",
  "c_suv": "Позашляховик",
  "c_convertible": "Відкр. верх",
  "c_estate": "Універсал",
  "c_pickup": "Пікап",
  "c_mpv": "Мінівен",
  "c_van": "Фургон",
  "c_dragster": "Драгстер",
  "c_all-rounder": "Всесторонній",
  "c_twister": "Для звивистих",
  "c_petrol": "Бензин",
  "c_diesel": "Дизель",
  "c_electric": "Электро",
  "c_hybrid": "Гібрид",
  "c_bioethanol": "Біоетанол",
  "c_hydrogen": "Водень",
  "c_misc": "Різне",
  "c_frontEngine": "Переднє",
  "c_midEngine": "Середнє",
  "c_mixedEngine": "Змішане",
  "c_rearEngine": "Заднє",
  "c_mid-rearEngine": "Зміщене назад",
  "t_drag100b": "0-100-0mph",
  "t_drag100": "0-100mph",
  "t_drag120": "0-120mph",
  "t_drag150b": "0-150-0mph",
  "t_drag150": "0-150mph",
  "t_drag170": "0-170mph",
  "t_drag200": "0-200mph",
  "t_drag60b": "0-60-0mph",
  "t_drag60": "0-60mph",
  "t_mile1": "Траса, 1 миля",
  "t_mile1r": "Траса, 1 миля (R)",
  "t_gForcer": "Тест на перевантаження (R)",
  "t_gForce": "Тест на перевантаження",
  "t_slalom": "Тест для слалому",
  "t_slalomr": "Тест для слалому (R)",
  "t_mile2": "Траса, 1/2 милі",
  "t_mile4": "Траса, 1/4 милі",
  "t_mile4r": "Траса, 1/4 милі (R)",
  "t_drag100150": "100-150mph (R)",
  "t_drag30130": "30-130mph (R)",
  "t_drag50150": "50-150mph (R)",
  "t_drag75125": "75-125mph (R)",
  "t_airplaneHangars": "Авіаційні ангари",
  "t_airplaneSlalom": "Авіаційний слалом",
  "t_hairpin": "Серпантин",
  "t_mnCity": "Монако: Міські вулиці",
  "t_northloop": "Northloop",
  "t_northloop1": "Northloop 1",
  "t_northloop2": "Northloop 2",
  "t_northloop3": "Northloop 3",
  "t_northloop4": "Northloop 4",
  "t_northloop5": "Northloop 5",
  "t_canyonTour": "Каньйон: Експедиція",
  "t_tRoad": "Звивиста дорога",
  "t_carPark": "Парковка",
  "t_csMed": "Вулиці: Сер.",
  "t_csSmall": "Вулиці: Мал.",
  "t_indoorKart": "Закритий картинг",
  "t_fast": "Швидка траса",
  "t_fastr": "Швидка траса (R)",
  "t_testBowl": "Тестове коло",
  "t_testBowlr": "Тестове коло (R)",
  "t_kart": "Траса для картингу",
  "t_tCircuit": "Звивиста траса",
  "t_tCircuitr": "Звивиста траса (R)",
  "t_figureEight": "\"Вісімка\"",
  "t_forest": "Лісова дорога",
  "t_mtHairpin": "Гори: Серпантин",
  "t_frozenLake": "Замерзле озеро",
  "t_hClimb": "Підйом на пагорб",
  "t_draglshape": "L-подібна траса",
  "t_mtTwisty": "Гори: Звивиста дорога",
  "t_mnHairpin": "Монако: Серпантин",
  "t_tokyoOverpass": "Токіо: Мостик",
  "t_mnGforce": "Монако: Тест на перевантаження",
  "t_mnCityNarrow": "Монако: Вузькі вулиці",
  "t_mnCityLong": "Монако: Довгі міські вулиці",
  "t_moto": "Траса для мотокросу",
  "t_oceanSlalom": "Пляжний слалом біля океану",
  "t_rallySmall": "Раллі-крос: Мал.",
  "t_rallyMed": "Раллі-крос: Сер.",
  "t_runwayDrag": "Злітка",
  "t_tokyoLoop": "Токіо: Петля",
  "t_tokyoOffRamp": "Токіо: З'їзд",
  "t_waterDrag": "Траса: Набережна",
  "t_hClimbr": "Підйом на пагорб (R)",
  "t_mtHill": "Гори: Підйом на пагорб",
  "t_mtSlalom": "Гори: Слалом",
  "t_oceanCity": "Міські вулиці біля океану",
  "t_oceanHighway": "Магістраль біля океану",
  "t_oceanLongDrag": "Довга траса біля океану",
  "t_oceanParking": "Парковка біля океану",
  "t_oceanShortDrag": "Коротка траса біля океану",
  "t_runwayDragSlalom": "Злітка і слалом",
  "t_tokyoGforce": "Токіо: Тест на перевантаження",
  "t_tokyoBridge": "Токіо: Міст",
  "t_forestSlalom": "Лісовий слалом",
  "t_tokyoDrag": "Токіо: Траса",
  "t_serviceRoads": "Службові дороги",
  "t_mtIncline": "Гори: Дорога з ухилом",
  "t_mtTour": "Гори: Експедиція",
  "t_forestRiver": "Лісова переправа",
  "t_speedbump12km": "Гонка з перешкодами: 1/2 км",
  "t_speedbump1km": "Гонка з перешкодами: 1 км",
  "t_runwayLoop": "Петля на зліткі",
  "m_by": "by",
  "t_smallRoad": null,
  "t_townSlalom": null,
  "t_townSprint": null,
  "t_townTour": null,
  "t_cross": null,
  "t_fieldSlalom": null,
  "t_frozenRivSla": null,
  "t_riverSprint": null,
  "t_butte": "Крутий пагорб",
  "t_canyonLookout": "Каньйон: Огляд",
  "t_canyonDtRoad": "Каньйон: Грунтова дорога",
  t_canyonButte: "Каньйон: Крутий пагорб",
  t_lookout: "Огляд",
  t_dtRoad: "Грунтова дорога",
  "p_cgNotThisTime1": "Це завдання недоступне тут",
  "p_cgNotThisTime2": "Ми вирішили не ставити цей виклик TDR. Давайте пограємо в стару школу, просто для розваги. До TDR ми всі робили це, ми можемо зробити знову. Можливо, це може бути надто важко чи ризиковано, але це просто виклик. Інші працюють нормально.",
  "p_cgNotThisTime3": "чому Ми втратили деякі соціальні способи пошуку допомоги та рішень. Цього разу ми хочемо, щоб гравці допомагали один одному, а не TDR, який пропонує всі рішення. Допомога один одному є основою кожної спільноти. Discord, Facebook, Reddit, Youtube... Їх багато. Ти не в жодній спільноті? Є вкладка з багатьма з них, знайдіть той, який вам подобається:",
  "p_cgNotThisTime4": "Щоб було зрозуміло, це не платний доступ. Навіть патрони чи модератори не можуть бачити раунди.",
  "p_cgNotThisTime5": "Сподіваюся, ви розумієте. весело провести час!"
}