<template>
  <a
    href="https://www.patreon.com/topdrivesrecords"
    class="D_Button Main_OptionsButton"
    target="_blank"
    rel="noopener noreferrer">
    <svg style="width: 21px;" class="Main_DiscordLogo" version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 601.8 577.8" xml:space="preserve">
      <g fill-rule="evenodd" clip-rule="evenodd" fill="#FF424D">
        <path d="M385.5 1c-119.1 0-216 97-216 216.2 0 118.8 96.9 215.5 216 215.5 118.7 0 215.3-96.7 215.3-215.5C600.8 98 504.2 1 385.5 1zM1 576.8h105.4V1H1v575.8z"/>
      </g>
    </svg>
    <span>Patreon</span>
  </a>
</template>

<script>
export default {
  name: 'BaseDonateButton',
  components: {},
  props: {},
  data() {
    return {}
  },
  watch: {},
  beforeMount() {},
  mounted() {},
  computed: {},
  methods: {},
}
</script>

<style>
</style>