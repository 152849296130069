export default {
  "language": "Simplified Chinese 简体中文",
  "m_home": "主页",
  "m_compare": "比较",
  "m_challenges": "挑战",
  "m_charts": "列表",
  "m_options": "选项",
  "m_about": "关于",
  "m_copy": "复制",
  "m_listCars": "车辆列表",
  "m_search": "搜索",
  "m_searchCar": "搜索车辆",
  "m_type": "种类",
  "m_searchTracks": "搜索赛道",
  "m_selectTrack": "选择赛道",
  "m_printBy": "制作者",
  "m_display": "展示",
  "m_colors": "颜色",
  "m_more3dot": "更多…",
  "m_clear": "清除",
  "m_clearCars": "清除车辆",
  "m_trackset": "赛道组",
  "m_load": "加载",
  "m_loadChart": "加载列表",
  "m_image": "图片",
  "m_downloadPng": "下载PNG",
  "m_save": "保存",
  "m_cancel": "取消",
  "m_login": "登录",
  "m_emailVerification": "邮箱验证",
  "m_email": "邮箱",
  "m_gotoLogin": "前往登录",
  "m_username": "用户名",
  "m_password": "密码",
  "m_newPassword": "新密码",
  "m_repeatPassword": "重复密码",
  "m_library": "档案馆",
  "m_addLibrary": "加入档案馆",
  "m_sharableLink": "分享链接",
  "m_vChanges": "{版本}变更",
  "m_vNewPhotos": "{版本}新图片",
  "m_event": "赛事",
  "m_events": "赛事",
  "m_contributors": "上传者",
  "m_campaign": "活动",
  "m_memory": "记忆",
  "m_pleaseWait3dot": "请稍等…",
  "m_repeat": "重复",
  "m_forgot": "忘记",
  "m_filter": "筛选器|筛选器",
  "m_here": "这里",
  "m_allmostDone": "快完成了",
  "m_register": "注册",
  "m_saveToLibrary": "保存到档案馆",
  "m_round": "回合|回合",
  "m_requirements": "限制要求",
  "m_empty": "空",
  "m_emptyRound": "空回合",
  "m_other": "其他",
  "m_lose": "失败",
  "m_win": "赢！",
  "m_draw": "平局",
  "m_notime": "时间缺失",
  "m_select": "选择",
  "m_resetRound": "重置回合",
  "m_unlockRound": "解锁这个回合",
  "m_lockRound": "为我锁定这个回合",
  "m_delete": "删除",
  "m_approve": "批准",
  "m_analyze": "分析",
  "m_change": "变更",
  "m_findBest": "寻找最佳",
  "t_tokyoGforce": "东京重力测试",
  "t_tokyoDrag": "东京直线加速赛",
  "t_tokyoBridge": "东京桥",
  "t_testBowlr": "测试场（滚动）",
  "t_testBowl": "测试场",
  "t_dockUdrag": "造船厂U型赛道",
  "t_dockKart": "造船厂卡丁车赛道",
  "t_dockGforceDrag": "造船厂重力测试",
  "t_dockCity": "造船厂城市街道",
  "t_dockCirc": "造船厂赛道",
  "c_mid-rearEngine": "中后置",
  "c_mixedEngine": "混合位置",
  "c_rearEngine": "后置",
  "c_slick": "光头",
  "c_off-road": "越野",
  "c_all-surface": "通用",
  "c_standard": "标准",
  "c_performance": "性能",
  "c_high": "高",
  "c_low": "低",
  "c_mid": "中",
  "c_prize cars": "奖杯车",
  "c_non-prize cars": "非奖杯车",
  "c_prizeCar": "奖杯车",
  "c_bodyStyle": "车体形式",
  "c_enginePos": "引擎位置",
  "c_seats": "座位数量",
  "c_fuel": "燃料",
  "c_weight": "车重",
  "c_stock": "原厂",
  "c_tag": "标签|标签",
  "c_brand": "品牌|品牌",
  "c_country": "国家|国家",
  "c_clearance": "离地间隙|离地间隙",
  "c_tyre": "轮胎|轮胎",
  "c_year": "年份|年份",
  "c_topSpeed": "极速",
  "s_grass": "草地",
  "s_snow": "雪地",
  "s_sand": "沙地",
  "s_ice": "冰面",
  "s_gravel": "石砾",
  "s_dirt": "土路",
  "s_wet": "雨地",
  "s_aspht": "柏油",
  "s_dry": "干地",
  "c_handling": "操控",
  "c_hand": "操控|操控",
  "c_class": "级别|级别",
  "c_drive": "驱动|驱动",
  "c_saloon": "轿车",
  "c_roadster": "双座敞篷车",
  "c_coupe": "跑车",
  "c_hatchback": "掀背车",
  "c_suv": "越野车",
  "c_convertible": "四座敞篷车",
  "c_estate": "旅行车",
  "c_pickup": "皮卡车",
  "c_mpv": "商务车",
  "c_van": "客货两用车",
  "c_dragster": "直线车",
  "c_twister": "弯道车",
  "c_speedster": "高速车",
  "c_all-rounder": "全能车",
  "c_petrol": "汽油",
  "c_diesel": "柴油",
  "c_electric": "电动",
  "c_hybrid": "混动",
  "c_bioethanol": "生物燃料",
  "c_hydrogen": "氢能",
  "c_frontEngine": "前置",
  "c_midEngine": "中置",
  "c_tune": "升级",
  "c_name": "名称",
  "t_drag100b": "0-100-0mph",
  "t_drag100": "0-100mph",
  "t_drag120": "0-120mph",
  "t_drag150b": "0-150-0mph",
  "t_drag150": "0-150mph",
  "t_drag170": "0-170mph",
  "t_drag200": "0-200mph",
  "t_drag60b": "0-60-0mph",
  "t_drag60": "0-60mph",
  "t_mile1": "1英里直线加速",
  "t_mile1r": "1英里直线加速（滚动）",
  "t_mile2": "1/2英里直线加速",
  "t_mile4": "1/4英里直线加速",
  "t_mile4r": "1/4英里直线加速",
  "t_drag100150": "100-150mph (滚动)",
  "t_drag30130": "100-150mph (滚动)",
  "t_drag50150": "50-150mph (滚动)",
  "t_drag75125": "75-125mph (滚动)",
  "t_csMed": "城市街道（中型）",
  "t_csSmall": "城市街道（小型）",
  "t_townTour": "城市之旅",
  "t_fast": "高速赛道",
  "t_cross": "跨国之旅",
  "t_canyonTour": "峡谷之旅",
  "t_carPark": "停车场",
  "t_townSprint": "城市冲刺",
  "t_fastr": "高速赛道（滚动）",
  "t_figureEight": "8字形",
  "t_forest": "森林道路",
  "t_gForce": "重力测试",
  "t_gForcer": "重力测试（滚动）",
  "t_hairpin": "发夹弯路",
  "t_indoorKart": "室内卡丁车",
  "t_kart": "卡丁车赛道",
  "t_mnGforce": "摩纳哥重力测试",
  "t_mnHairpin": "摩纳哥发夹弯赛道",
  "t_mnCityNarrow": "摩纳哥狭窄街道",
  "t_mnCity": "摩纳哥街道",
  "t_mnCityLong": "摩纳哥街道加长版",
  "t_moto": "越野赛赛道",
  "t_mtHairpin": "山间发夹弯赛道",
  "t_mtIncline": "山间倾斜路",
  "t_mtTour": "山地之旅",
  "t_mtTwisty": "山间弯曲路",
  "t_northloop": "纽北赛道",
  "t_northloop1": "纽北第一段",
  "t_northloop2": "纽北第二段",
  "t_northloop3": "纽北第三段",
  "t_northloop4": "纽北第四段",
  "t_northloop5": "纽北第五段",
  "t_oceanCity": "海洋城市街道",
  "t_slalom": "曲行测试",
  "t_slalomr": "曲行测试（滚动）",
  "t_serviceRoads": "服务区路",
  "t_runwayLoop": "跑道环形道",
  "t_runwayDrag": "跑道",
  "t_oceanShortDrag": "海洋短道",
  "t_oceanParking": "海洋停车场",
  "t_oceanHighway": "海洋大道",
  "t_oceanLongDrag": "海洋长道",
  "t_drag62": "0-62mph",
  "t_drag124": "0-124mph",
  "c_misc": "混合",
  "m_charLeast": "最少{n}个字符",
  "m_submitReview": "提交预览",
  "m_resetSolutions": "只重制方案",
  "m_includeDownvote": "包含有争议的圈速？",
  "m_showDataFromPast": "显示旧版本数据",
  "m_showOldTags": "显示已弃用标签",
  "m_challengeName": "挑战名称",
  "m_eventName": "活动名称",
  "m_numberRounds": "回合数量",
  "m_yes": "是",
  "m_no": "否",
  "m_new": "新",
  "m_by": "由",
  "m_checkOut": "退出登录",
  "m_templateGuide": "模板参考",
  "m_createNewCg": "创建新挑战",
  "m_createNewEvent": "创建新活动",
  "m_title": "标题",
  "m_twisty": "弯道",
  "m_drag": "直线加速",
  "m_city": "城市",
  "m_rqLimit": "RQ限制",
  "m_points": "分数",
  "m_time": "时间",
  "m_timeToBeat": "需要打败的时间",
  "m_uploadPrint": "上传打印",
  "m_done": "完成",
  "m_sort": "分类",
  "m_send": "发送",
  "m_backHome": "回到主页",
  "m_any": "任何",
  "m_rarity": "稀有度",
  "m_car": "车｜车辆",
  "m_changed": "已改变的",
  "m_noRecords": "无记录",
  "m_showOtherTracks": "显示其他赛道",
  "m_showMore": "显示更多",
  "m_lastContribution": "最新贡献",
  "m_nothingFound": "找不到",
  "m_changedCars": "调整过的车辆",
  "m_uploadSuccess": "上传成功",
  "m_noData": "无数据显示",
  "m_noTimesFound": "无时间显示",
  "m_logoutSuccess": "登出成功",
  "m_loginSuccess": "你已经登陆",
  "m_deleteSuccess": "成功删除",
  "m_saveSuccess": "成功保存",
  "m_approveSuccess": "成功批准",
  "m_sentReview": "上传预览",
  "m_language": "语言",
  "m_logout": "登出",
  "m_resetSavedHand": "重制为最初方案",
  "m_showCustomTunes": "显示自定义升级",
  "m_kingForceVertical": "改为垂直显示",
  "m_showBigCards": "显示大卡片",
  "m_chartHideOutOfFilter": "隐藏不在筛选内的车辆",
  "m_meta": "强力车辆",
  "m_race": "比赛",
  "m_useTrackList": "使用赛道列表",
  "m_modShowSuspectTimes": "隐藏可能错误的时间",
  "m_newByCopy": "新副本",
  "m_eventShowOnlyPicks": "只显示被挑选的",
  "m_eventForcePicks": "强制展示精选",
  "m_showPointsCgForce": "显示分数",
  "m_statsView": "统计视图",
  "m_multi": "多",
  "m_multiTags": "多项标签",
  "m_hidden": "隐藏",
  "m_enablePicks": "启用选择",
  "m_clearPicks": "清除选择",
  "m_findRqSavers": "寻找低分高能车辆",
  "m_homePointsToggle": "启用开关以显示分数",
  "m_zoom": "放大",
  "m_review": "评论|评论",
  "m_addReview": "添加评论",
  "m_clubs": "俱乐部",
  "m_showContributors": "显示车辆上传者",
  "m_tracksetName": "赛道组名字",
  "m_tracksets": "赛道组",
  "m_reqs": "要求",
  "m_reqsName": "要求名字",
  "m_daySelector": "日期选择",
  "m_current": "当前",
  "m_saveDay": "保存日期",
  "m_createNewTrackset": "创建新赛道组",
  "m_createNewReqs": "创建新要求",
  "m_saveTrackset": "保存赛道组",
  "m_saveReqs": "保存要求",
  "m_saveOriginalOrder": "以原始顺序保存",
  "m_showOriginalOrder": "展示原始顺序",
  "m_filterPage": "过滤器页面",
  "m_notCurrent": "当前不可用",
  "m_admin": "管理员",
  "m_carFinder": "车辆寻找器",
  "m_newPhotos": "新图片",
  "m_testPoints": "测试分数",
  "m_downTimes": "有争议的圈速",
  "m_translationTool": "翻译工具",
  "m_newCar": "新车",
  "m_relativeBest": "相对最佳",
  "m_2xmultiplier": "双倍增幅器",
  "m_cheatSheet": "速查表",
  "m_simulateUntilGetAll": "模拟全部",
  "m_simulateUntilGetOne": "模拟一个",
  "m_simulate": "模拟",
  "m_simulating": "正在模拟",
  "m_impossibleRemoved": "{个数} 不可能的车被移除",
  "m_count": "数量",
  "m_quantityOfCars": "汽车数量",
  "m_success": "成功",
  "m_failed": "失败",
  "m_status": "状态",
  "m_cost": "花费",
  "m_stop": "停止",
  "m_openLimit": "开启上限",
  "m_probabilityPerOpen": "每次打开的概率",
  "m_cumulativeProbability": "总计概率",
  "m_superLucky": "极其幸运",
  "m_veryLucky": "SSR",
  "m_goodLuck": "运气不错",
  "m_littleLucky": "运气尚可",
  "m_badLuck": "运气不佳",
  "m_worstLuck": "烂手气",
  "m_zeroLuck": "寄",
  "m_impossible": "莫滴可能",
  "m_customizePack": "自定义卡包",
  "m_impossiblePack": "这个卡包不存在",
  "m_impossibleCondition": "这个条件不存在",
  "m_permanents": "永久的",
  "p_userSentCgForAnalyse": "感谢！你的回合将被分析。如果你想谈论的话请加入Discord。",
  "p_emptyRoundForUser": "这一回合还没完成。你可以帮助创建它，然后提交审查。",
  "p_emptyRoundVoteForUser": "这次提交一切顺利吗？",
  "p_emptyRound2": "这一回合还没完成。如果你在这一回合，你可以帮助列入它。",
  "p_emptyRoundLogin": "登录以开始创建这一回合或联系我们Discord服务器上的版主:",
  "p_lockRoundPhrase": "为你锁定这一回合让你制作",
  "p_modDoingRound": "{mod}正在制作这个回合",
  "p_patronsOnly": "此功能仅适用于赞助者(patrons)（{tier}级别）。",
  "p_bestOfDescription": "它返回给定赛道和过滤器的最佳汽车列表。举个例子:",
  "p_chartsDescription": "它将一个赛道的所有数据库放在一个图表中。例如演示:",
  "p_templateWarning": "确保您的模板对社区有用，否则将不会被批准。",
  "p_about1": "该项目由TiagoXavi制作，与Hutch游戏有限公司无关。",
  "p_about2": "任何问题或建议，请加入Discord服务器或发送电子邮件(mighty.boy@topdrivesrecords.com).\n。",
  "p_about3": "TDR对所有人都是免费的，并且没有广告。您可以支持该项目以保持运作并接收新功能！",
  "p_resetPassHelper": "输入你的邮箱以接收重置密码的链接",
  "p_resetPassIfExist": "这个邮箱已经有注册的账号，一封用于重设密码的邮件已发到你的邮箱。",
  "p_confirmationWillSent": "将会发出确认邮件。",
  "p_checkYourEmailBox": "请查看你的收件箱。",
  "p_spamToo": "垃圾邮箱也请查看！",
  "p_somethingWrong": "有什么地方出错了。",
  "p_beforeLeave": "你确认要离开吗？",
  "p_eventsKingDescription": "每个稀有度只能看到一辆车。以下是完整列表的工作方式:",
  "p_eventsKingLogin": "登录以查看每个稀有度的最佳车辆",
  "p_eventHiddenForPatreons": "此活动是针对至少T3级别赞助(patreons)人员的预览",
  "g_joinDiscord": "加入TDR Discord服务器，谈论您的模板的改进或您的模板被拒绝的原因。",
  "g_rule0": "你的模板应该有一个给汽车的合理背景。比如相同的标签，相同的国家，相同的轮胎，相同的品牌，相同的级别...或者像Hutch那样的任何背景作为事件/挑战的要求。",
  "g_rule1": "您应该将该背景中所有可用的汽车都放入其中，或者至少尝试添加所有汽车。不要只放入你拥有的车辆。",
  "g_rule2": "该模板应该可以比较具有类似数据的汽车。在干燥的沥青赛道上比较后驱性能胎和四驱全地形轮胎是没有意义的。",
  "g_rule3": "如果有理由的话，只能在混合公路和越野赛道上使用。",
  "g_rule4": "不需要把所有的赛道都放在游戏中。该程序将会有性能问题。",
  "g_rule5": "尝试按类型对赛道进行分类，如将直道赛为一组或者相同路面为一组。",
  "g_rule6": "尝试按RQ（高的优先）或所选赛道的性能对车进行分类。",
  "g_rule7": "模板不需要填写所有圈速，但您可以在提交前填写您能填写的内容。",
  "g_rule8": "使用概括性标题。",
  "g_rule9": "查看是否已经存在与您相同用途的模板。",
  "t_airplaneHangars": "飞机库",
  "t_airplaneSlalom": "飞机障碍道",
  "t_smallRoad": "支道",
  "t_butte": "山丘",
  "t_canyonButte": "峡谷山丘",
  "t_lookout": "望风",
  "t_canyonLookout": "峡谷瞭望台",
  "t_dtRoad": "土路",
  "t_canyonDtRoad": "峡谷土路",
  "t_townSlalom": "城市障碍",
  "t_fieldSlalom": "旷野障碍",
  "t_forestRiver": "森林河口",
  "t_forestSlalom": "森林障碍",
  "t_frozenLake": "结冻冰湖",
  "t_frozenRivSla": "冰河障碍",
  "t_hClimb": "爬坡赛",
  "t_hClimbr": "爬坡赛（滚动）",
  "t_draglshape": "L形道",
  "t_mtHill": "山间盘山路",
  "t_mtSlalom": "山间坡道",
  "t_oceanSlalom": "海洋沙滩障碍",
  "t_rallyMed": "汽车拉力赛道（中型）",
  "t_rallySmall": "汽车拉力赛道（小型）",
  "t_riverSprint": "冰河冲刺",
  "t_runwayDragSlalom": "跑道&障碍道",
  "t_speedbump12km": "1/2公里减速带",
  "t_speedbump1km": "1公里减速带",
  "t_tokyoLoop": "东京环道（滚动）",
  "t_tokyoOffRamp": "东京匝道（滚动）",
  "t_tokyoOverpass": "东京立交桥（滚动）",
  "t_tCircuit": "弯曲赛道",
  "t_tCircuitr": "弯曲赛道（滚动）",
  "t_tRoad": "弯曲路",
  "t_waterDrag": "海滨直线加速",
  "t_laguna": "拉古纳赛卡",
  "t_lumberTwisty": "伐木场弯曲赛道",
  "t_desertHill": "沙漠山丘拉力赛",
  "t_desertSmall": "沙漠小型拉力赛",
  "t_desertBig": "沙漠大型拉力赛",
  "t_desertRallyDirt": "沙漠尘土拉力赛",
  "t_dockAltCirc": "造船厂备用赛道",
  "t_lumberForest": "伐木厂森林拉力赛",
  "t_lumberRiver": "伐木厂河流拉力赛",
  "t_dealsGap": "蒂尔斯山口",
  "t_dealsGapBack": "蒂尔斯山口蛇形路",
  "t_dealsGap1": "蒂尔斯山口接力赛第1段",
  "t_dealsGap2": "蒂尔斯山口接力赛第2段",
  "t_dealsGap3": "蒂尔斯山口接力赛第3段",
  "t_dealsGap4": "蒂尔斯山口接力赛第4段",
  "t_dealsGap5": "蒂尔斯山口接力赛第5段",
  "c_brakeClass": "刹车等级",
  "c_brake": "刹车",
  "t_nwCircuit": "纽约环道",
  "t_nwDrag": "纽约加速赛",
  "t_nwGforce": "纽约重力加速赛",
  "t_nwLoop": "纽约时代广场绕圈赛",
  "t_nwCathedral": "纽约大教堂",
  "t_nwSlalom": "纽约火车回旋赛",
  "t_nwTour": "纽约中心公园巡回赛",
  "p_linkDiscordExplanationP1": "要链接您的账号，您需要:",
  "m_linkDiscord": "您的Discord账号链接",
  "m_sixDigitCode": "六位数代码",
  "m_discordUserId": "Discord用户ID",
  "m_discordLinkFinish": "您的 TDR 和 Discord 帐户已连接！",
  "m_submitYourCommunity": "上传我的群组",
  "m_wantToJoin": "想进来玩玩嘛？",
  "m_private": "私人的",
  "m_public": "公开的",
  "m_official": "官方的",
  "m_platform": "平台",
  "m_selectPlatform": "选择平台",
  "m_selectCountry": "选择国家",
  "m_userCount": "用户数量",
  "m_description": "简介",
  "m_optional": "额外的",
  "m_link": "链接",
  "m_unlinkDiscord": "解绑我的Discord",
  "m_informationForInvite": "邀请信息",
  "m_edit": "编辑",
  "m_inviter": "邀请人",
  "m_requestSent": "发送请求",
  "p_linkDiscordExplanationCommu": "要上传您的私人群组，您需要:\n",
  "p_linkDiscordExplanationP2": "·Discord帐户·登录TDR Discord服务器·获取您的Discord用户ID（使用指令/在TDR Discord服务器上的用户）",
  "p_linkDiscordCodeSent": "代码已发送到Discord私聊中",
  "p_informationForInvite": "加入社区的用户需要提供的信息，如电子邮件、电话、用户名等……这将显示给想加入您社区的用户",
  "p_inviteYourDiscord": "您的Discord会收到加入申请，请多多留意",
  "p_privateDescript": "人们将在申请后加入（您需要一个 Discord 帐户）",
  "p_publicDescript": "这是任何人都可以加入的公开链接",
  "p_userSentCommunityForAnalyse": "十分感谢！TDR的员工将评估您的群组。",
  "p_communityToBeInvited": "要获得邀请，请输入下面的要求：",
  "p_cgNotThisTime1": "此挑战不在此处提供",
  "p_cgNotThisTime2": "我们决定不在 TDR 上进行此挑战。让我们以老派的方式玩，只是为了好玩。在 TDR 之前我们都这样做过，我们可以再做一次。也许它可能太难或太冒险，但只有这个挑战。其他的都在正常运行。",
  "p_cgNotThisTime3": "为什么？我们失去了一些寻求帮助和解决方案的社交方式。这次我们希望看到玩家互相帮助，而不是 TDR 提供所有解决方案。互相帮助是每个社区的核心。Discord、Facebook、Reddit、Youtube……有很多。你不在任何社区吗？有一个标签有很多，找到你喜欢的一个：",
  "p_cgNotThisTime4": "只是要清楚，这不是付费墙。甚至赞助人或版主都无法看到回合。",
  "p_cgNotThisTime5": "我希望你能理解。玩得开心！"
}