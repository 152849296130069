export default {
  "language": "Swedish",
  "m_campaign": "Kampanj",
  "m_home": "Hem",
  "m_compare": "Jämför",
  "m_challenges": "Utmaningar",
  "m_event": "Evenemang",
  "m_events": "Evenemang",
  "m_charts": "Diagram",
  "m_options": "Inställningar",
  "m_about": "Om",
  "m_vChanges": "{version} Ändringar",
  "m_vNewPhotos": "{version} Nya Bilder",
  "m_library": "Bibliotek",
  "m_addLibrary": "Lägg till i biblioteket",
  "m_sharableLink": "Länk för delning",
  "m_copy": "Kopiera",
  "m_listCars": "Lista över bilar",
  "m_type": "Typ",
  "m_search": "Sök",
  "m_searchCar": "Sök efter bil",
  "m_searchTracks": "Sök efter bana",
  "m_selectTrack": "Välj bana",
  "m_contributors": "Bidragsgivare",
  "m_printBy": "bild av",
  "m_display": "Display",
  "m_colors": "Färger",
  "m_more3dot": "Mer…",
  "m_trackset": "Uppsättning banor",
  "m_clear": "Rensa",
  "m_clearCars": "Rensa bilar",
  "m_load": "Ladda",
  "m_loadChart": "Ladda diagram",
  "m_memory": "Minne",
  "m_image": "Bild",
  "m_pleaseWait3dot": "Var vänlig vänta…",
  "m_downloadPng": "Ladda ner PNG",
  "m_save": "Spara",
  "m_cancel": "Avbryt",
  "m_login": "Logga in",
  "m_gotoLogin": "Gå till logga in",
  "m_email": "Email",
  "m_emailVerification": "Verifiera email",
  "m_username": "Användarnamn",
  "m_password": "Lösenord",
  "m_newPassword": "Nytt lösenord",
  "m_repeatPassword": "Repetera lösenord",
  "m_repeat": "Repetera",
  "m_charLeast": "minst {n} symboler",
  "m_register": "Registrera",
  "m_forgot": "Glömde",
  "m_allmostDone": "Nästan klar",
  "m_here": "Här",
  "m_filter": "Filter | Filter",
  "m_change": "Ändra",
  "m_analyze": "Analysera",
  "m_submitReview": "Lämna in för granskning",
  "m_saveToLibrary": "Spara till bibliotek",
  "m_delete": "Ta bort",
  "m_approve": "Godkänn",
  "m_round": "Runda | Rundor",
  "m_requirements": "Krav",
  "m_empty": "Töm",
  "m_emptyRound": "Töm runda",
  "m_lockRound": "Lås rundan för mig",
  "m_unlockRound": "Lås upp rundan",
  "m_findBest": "Hitta den bästa",
  "m_other": "Andra",
  "m_win": "vinner",
  "m_lose": "förlorar",
  "m_draw": "lika",
  "m_notime": "ingen tid",
  "m_select": "välj",
  "m_resetSolutions": "Återställ endast lösningar",
  "m_resetRound": "Återställ runda",
  "m_includeDownvote": "Tid med downvote ingår?",
  "m_showDataFromPast": "Vida data från äldre versioner",
  "m_showOldTags": "Visa förringade taggar",
  "m_challengeName": "Utmaningens namn",
  "m_eventName": "Evenemangets namn",
  "m_numberRounds": "Antal rundor",
  "m_yes": "Ja",
  "m_no": "Nej",
  "m_new": "Ny",
  "m_by": "genom",
  "m_checkOut": "Kolla in",
  "m_templateGuide": "Mallriktlinjer",
  "m_createNewCg": "Skapa ny utmaning",
  "m_createNewEvent": "Skapa nytt evenemang",
  "m_title": "Titel",
  "m_twisty": "Slingrande",
  "m_drag": "Drag",
  "m_city": "Stad",
  "m_rqLimit": "RQ-gräns",
  "m_points": "Poäng",
  "m_time": "Tid",
  "m_timeToBeat": "Tid att slå",
  "m_uploadPrint": "Ladda up print",
  "m_done": "Klar",
  "m_sort": "Sortera",
  "m_send": "Skicka",
  "m_backHome": "Tillbaka hem",
  "m_any": "Vilken som",
  "m_rarity": "Sällsynthet",
  "m_car": "Bil | Bilar",
  "m_changed": "Ändrad",
  "m_noRecords": "Inga rekord",
  "m_showOtherTracks": "Visa andra banor",
  "m_showMore": "Visa mer",
  "m_lastContribution": "Senaste bidragen",
  "m_nothingFound": "Inget hittat",
  "m_changedCars": "ändrade bilar",
  "m_uploadSuccess": "Uppladdning lyckad",
  "m_noData": "Ingen data att visa",
  "m_noTimesFound": "Inga tider hittade",
  "m_logoutSuccess": "Utloggning lyckad",
  "m_loginSuccess": "Du loggade in",
  "m_deleteSuccess": "Borttaget",
  "m_saveSuccess": "Sparat",
  "m_approveSuccess": "Lyckad bekräftelse",
  "m_sentReview": "Inlämnad för granskning",
  "m_language": "Språk",
  "m_logout": "Logga ut",
  "m_resetSavedHand": "Återställ till ursprunglig lösning",
  "m_showCustomTunes": "Visa anpassade tider",
  "m_kingForceVertical": "Ändra bild till vertikal",
  "m_showBigCards": "Visa stora kort",
  "m_chartHideOutOfFilter": "Uteslut bilar ur filter",
  "m_meta": "Meta",
  "m_race": "Race",
  "m_useTrackList": "Använd lista över banor",
  "m_modShowSuspectTimes": "Göm obekräftade tider",
  "m_newByCopy": "Nu genom kopia",
  "m_eventShowOnlyPicks": "Visa endast valda",
  "m_eventForcePicks": "Tvinga att visa valda",
  "m_showPointsCgForce": "visa poäng",
  "m_statsView": "Visa statistik",
  "m_multi": "Multi",
  "m_multiTags": "Flera taggar",
  "m_hidden": "Dold",
  "m_enablePicks": "Möjliggör val",
  "m_clearPicks": "Rensa val",
  "m_findRqSavers": "Hitta RQ-sparare",
  "m_homePointsToggle": "Visa knapp för att visa poäng",
  "m_zoom": "Zooma",
  "m_review": "Recension | Recensioner",
  "m_addReview": "Lägg till recension",
  "m_clubs": "Klubbar",
  "m_showContributors": "Visa bidragsgivare till bil",
  "m_tracksetName": "Namn på bangrupp",
  "m_tracksets": "Bangrupper",
  "m_reqs": "Krav",
  "m_reqsName": "Namn på krav",
  "m_daySelector": "Välj dag",
  "m_current": "nuvarande",
  "m_saveDay": "Spara dag",
  "m_createNewTrackset": "Skapa ny bangrupp",
  "m_createNewReqs": "Skapa nytt krav",
  "m_saveTrackset": "Spara bangrupp",
  "m_saveReqs": "Spara krav",
  "m_saveOriginalOrder": "Spara ursprunglig ordning",
  "m_showOriginalOrder": "Visa ursprunglig ordning",
  "m_filterPage": "Filtrera sida",
  "m_notCurrent": "Inte nuvarande",
  "m_admin": "Admin",
  "m_carFinder": "Hitta bil",
  "m_newPhotos": "Nya bilder",
  "m_testPoints": "Testa poäng",
  "m_downTimes": "Nedröstade tider",
  "m_translationTool": "Översättningsverktyg",
  "m_newCar": "Ny bil",
  "m_relativeBest": "Jämfört med bästa",
  "m_2xmultiplier": "2x multipel",
  "m_cheatSheet": "Fuskpapper",
  "m_simulateUntilGetAll": "Simulera tills alla",
  "m_simulateUntilGetOne": "Simulera tills en",
  "m_simulate": "Simulera",
  "m_simulating": "Simulerar",
  "m_impossibleRemoved": "{count} omöjliga bilar borttagna",
  "m_count": "Antal",
  "m_quantityOfCars": "Antal bilar",
  "m_success": "Lyckat",
  "m_failed": "Misslyckat",
  "m_status": "Status",
  "m_cost": "Kostnad",
  "m_stop": "Avbryt",
  "m_probabilityPerOpen": "Chans per öppning",
  "m_cumulativeProbability": "Kumulativ chans",
  "m_superLucky": "Mycket Tur",
  "m_veryLucky": "Väldigt Tur",
  "m_goodLuck": "Bra Tur",
  "m_littleLucky": "Lite Tur",
  "m_badLuck": "Dålig Tur",
  "m_worstLuck": "Väldigt Dålig Tur",
  "m_zeroLuck": "Noll Tur",
  "m_impossible": "Omöjligt",
  "m_customizePack": "Anpassa pack",
  "m_impossiblePack": "Detta pack är inte möjligt",
  "m_impossibleCondition": "Denna förutsättning är inte möjlig",
  "m_permanents": "Permanenta",
  "m_linkDiscord": "Länka ditt discordkonto",
  "m_sixDigitCode": "Kod med sex siffror",
  "m_discordUserId": "Discord användar-ID",
  "m_discordLinkFinish": "Ditt TDR och Discordkonto är nu länkade!",
  "m_submitYourCommunity": "Lägg till min gemenskapsgrupp",
  "m_wantToJoin": "Vill du ansluta?",
  "m_private": "Privat",
  "m_public": "Offentlig",
  "m_official": "Officiell",
  "m_platform": "Plattform",
  "m_selectPlatform": "Välj plattform",
  "m_selectCountry": "Välj land",
  "m_userCount": "Antal användare",
  "m_description": "Beskrivning",
  "m_optional": "Valbar",
  "m_informationForInvite": "Information för inbjudan",
  "m_link": "Länk",
  "m_unlinkDiscord": "Avlänka min discord",
  "m_inviter": "Inbjudare",
  "m_requestSent": "Förfrågan skickad",
  "m_edit": "Ändra",
  "m_saveToFile": "Spara till fil",
  "m_loadFile": "Ladda fil",
  "m_replace": "Byt ut",
  "m_eventPack": "Evenemangspaket",
  "m_accelCurveMaker": "Accelerationskurva-byggare",
  "m_speed": "Hastighet",
  "m_clearBoard": "Rensa bräde",
  "m_longTerm": "Långsiktig",
  "m_selectType": "Välj typ",
  "m_rewardedCar": "Belönade bilar",
  "m_deleteEvent": "Ta bort evenemang",
  "m_paste": "Klistra in",
  "m_date": "Datum",
  "m_dateEnd": "Datum för slut",
  "m_images": "Bilder",
  "m_links": "Länkar",
  "m_exportToTimeline": "Exportera till tidslinje",
  "m_spec": "Spec",
  "m_text": "Text",
  "m_noGoal": "Inget mål",
  "m_specificCar": "Specifik bil",
  "m_specificAttr": "Specifik egenskap",
  "m_nextPage": "Nästa sida",
  "m_previousPage": "Föregående sida",
  "m_videoShowcase": "Animerade kort",
  "m_video": "Video",
  "m_addVideo": "Lägg till video",
  "m_removeVideo": "Ta bort video",
  "m_toggleVideo": "Växla video",
  "m_downloadImage": "Ladda ner bild",
  "m_removeCar": "Ta bort bil",
  "m_needHelp": "Behöver du hjälp?",
  "m_instructions": "Instruktioner",
  "p_userSentCgForAnalyse": "Tack! Fina rundor kommer att analyseras. Anslut till Discord för att diskutera det om du vill.",
  "p_emptyRoundForUser": "Denna runda är inte klar ännu. Du kan hjälpa till att skapa den och seden skicka in den för granskning.",
  "p_emptyRoundVoteForUser": "Är allt bra med den här inlämningen?",
  "p_emptyRound2": "Denna runda är inte klar än. Om du är på denna runda kan du hjälp att inkludera den.",
  "p_emptyRoundLogin": "Logga in för att börja skapa denna runda, eller kontakta en moderator på vår discord-server.",
  "p_lockRoundPhrase": "Lås denna runda för dig för att börja göra den",
  "p_modDoingRound": "{mod} gör denna runda",
  "p_patronsOnly": "Denna funktion är endast tillgänglig för (Tier {tier}).",
  "p_bestOfDescription": "Den ger en lista av de bästa bilarna för en bana utifrån ett filter. Exempelvis:",
  "p_chartsDescription": "Den lägger hela databasen för en bana i ett diagram. För demonstration:",
  "p_templateWarning": "Försäkra dig om att din mall är användbar för andra. Annars kommer den inte godkännas.",
  "p_about1": "Det här projektet skapades av TiagoXavi och är inte relaterat till Hutch Games Ltd.",
  "p_about2": "Vid problem eller förslag, var vänlig anslut till Discordservern eller skicka ett email (mighty.boy@topdrivesrecords.com).",
  "p_about3": "TDR är gratis för alla och reklamfritt. Du kan bidra till projektet och se till att det fortsätter. För det får du också nya funktioner!",
  "p_resetPassHelper": "Ange din email för att få en länk till att återställa ditt lösenord",
  "p_resetPassIfExist": "Om en användare med denna email finns, har en länk för återställning av lösenord skickats.",
  "p_confirmationWillSent": "Bekräftelse-email kommer skickas",
  "p_checkYourEmailBox": "Var vänlig, kontrollera din email inbox",
  "p_spamToo": "Spam box också!",
  "p_somethingWrong": "Något gick fel",
  "p_beforeLeave": "Är du säker att du vill lämna?",
  "p_eventsKingDescription": "Du kan endast se 1 bil per sällsynthet. Detta är hur hela listan fungerar:",
  "p_eventsKingLogin": "Logga in för att se den bästa bilen för varje sällsynthet.",
  "p_eventHiddenForPatreons": "Detta evenemang är en förblick som endast är tillgänglig för patreons med minst tier 3.",
  "p_linkDiscordExplanationCommu": "För att skicka in din privata gemenskapsgrupp måste du:",
  "p_linkDiscordExplanationP1": "För att länk ditt konto måste du:",
  "p_linkDiscordExplanationP2": "• Ett Discord-konto •Vara med i TDRs Discord-server • Få ditt Discord Användar-ID (is the command / user on TDR Discord server)",
  "p_linkDiscordCodeSent": "En kod har skickats som privat meddelande på Discord.",
  "p_informationForInvite": "Den information folk behöver för att ansluta till din gemenskapsgrupp, som email, telefonnummer, användarnamn… Detta kommer visas för användare som vill ansluta till din gemenskapsgrupp",
  "p_inviteYourDiscord": "Du kommer att på förfrågningar att ansluta på discord. Håll ögonen öppna för dem.",
  "p_privateDescript": "Folk kommer att förfråga att få ansluta (Du behöver ett Discord-konto)",
  "p_publicDescript": "Detta är en offentlig länk och alla kan ansluta",
  "p_userSentCommunityForAnalyse": "Tack! Din gemenskapsgrupp kommer att granskas av TDRs team av moderatorer.",
  "p_communityToBeInvited": "För att inbjudas, skriv vad som bes om nedan:",
  "p_cgNotThisTime1": "Denna utmaning är inte tillgänglig här",
  "p_cgNotThisTime2": "Vi beslutade att inte visa denna utmaning på TDR. Låt oss spela på det gamla goda sättet. Innan TDR fanns gjorde vi alla det. Vi kan göra det igen. Kanske är det för svårt eller riskabelt, men det gäller bara den här utmaningen. Resterande funkar som vanligt.",
  "p_cgNotThisTime3": "Varför? Vi har förlorat det sociala i att söka hjälp och lösningar. Den här gången vill vi se spelare hjälpa varandra istället för att alla får lösningar från TDR. Att hjälpa är kärnan i alla gemenskaper. Discord, Facebook, Reddit, Youtube… Det  finns många. Är du inte del av en gemenskap? Här sitter du flera av dem. Välj en du gillar;",
  "p_cgNotThisTime4": "Bara för förtydligande — Detta är inte en paywall. Inte ens patrons eller moderatorer kan se rundorna.",
  "p_cgNotThisTime5": "Jag hoppas du förstår. Ha kul!",
  "g_joinDiscord": "About till TDR Discord-server för att diskutera förbättringar av din mall eller varför din mall blivit nekad.",
  "g_rule0": "Din mall ska ha ett sammanhang för bilar som går ihop. Exempelvis samma tag, samma land, samma däck, samma märke, samma klass… Eller annat som Hutch gör som ett krav för en utmaning eller evenemang.",
  "g_rule1": "Du bör välja alla bilar som är tillgängliga i det sammanhanget, eller åtminstone försöka. Välj inte bara de bilar du äger.",
  "g_rule2": "Mallen bör jämföra med bilar med liknande attributer. Det är orimligt att jämföra RWD Prestanda med 4WD Heltäckanfe på banor med torr asphalt.",
  "g_rule3": "Blanda endast banor med off road banor om det finns en anledning till det.",
  "g_rule4": "Du behöver inte inkludera alla banor i spelet. Appen kommer ha prestandaproblem.",
  "g_rule5": "Försök sortera banorna efter typ, som att gruppera drag-banor och banor med samma underlag.",
  "g_rule6": "Försök att sortera bilar efter RQ (högst först) eller efter prestation på valda banor.",
  "g_rule7": "Mallen behöver inte ha alla tider fyllda, men fyll i det du kan innan du skickar in.",
  "g_rule8": "Använd en beskrivande titel.",
  "g_rule9": "Kolla så att det inte redan finns en mall med samma syfte som din.",
  "s_dry": "Torr",
  "s_aspht": "Aspht",
  "s_wet": "Vått",
  "s_dirt": "Jord",
  "s_gravel": "Grus",
  "s_ice": "Is",
  "s_sand": "Sand",
  "s_snow": "Snö",
  "s_grass": "Gräs",
  "c_topSpeed": "Maxfart",
  "c_handling": "Svängförmåga",
  "c_hand": "@:c_svängförmåga | @:c_svängförmågor",
  "c_class": "Klass | Klasser",
  "c_year": "År | År",
  "c_tyre": "Däck | Däck",
  "c_drive": "Drivlina | Drivlinor",
  "c_clearance": "Markfrigång | Markfrigång",
  "c_country": "Land | Länder",
  "c_brand": "Märke | Märken",
  "c_tag": "Tag | Taggar",
  "c_stock": "Stock",
  "c_weight": "Vikt",
  "c_fuel": "Bränsle",
  "c_seats": "Säten",
  "c_enginePos": "Motorposition",
  "c_brakeClass": "Bromsklass",
  "c_brake": "Broms",
  "c_bodyStyle": "Kroppstyp",
  "c_prizeCar": "Prisbil",
  "c_prize cars": "Prisbilar",
  "c_non-prize cars": "Ej Prisbilar",
  "c_mid": "Med",
  "c_low": "Låg",
  "c_high": "Hög",
  "c_performance": "Prestanda",
  "c_standard": "Standard",
  "c_all-surface": "Heltäckande",
  "c_off-road": "Offroad",
  "c_slick": "Slick",
  "c_saloon": null,
  "c_roadster": null,
  "c_petrol": "Bensin",
  "c_diesel": "Diesel",
  "c_electric": "Eldriven",
  "c_hybrid": "Hybrid",
  "c_bioethanol": "Bioethanol",
  "c_hydrogen": "Väte",
  "c_misc": "Annat",
  "c_frontEngine": "Fram",
  "c_midEngine": "Mid",
  "c_mid-rearEngine": "Mid-bak",
  "c_mixedEngine": "Blandad",
  "c_rearEngine": "Bak",
  "c_name": "Namn",
  "c_template": "Mall",
  "c_tcs": "TCS",
  "c_abs": "ABS",
  "t_airplaneHangars": null,
  "t_airplaneSlalom": null,
  "t_smallRoad": null,
  "t_lookout": null,
  "t_canyonLookout": null,
  "t_carPark": null,
  "t_townSlalom": null,
  "t_townSprint": null,
  "t_csMed": null,
  "t_csSmall": null,
  "t_townTour": null,
  "t_fast": null,
  "t_fastr": null,
  "t_fieldSlalom": null,
  "t_forest": null,
  "t_forestSlalom": null,
  "t_frozenLake": null,
  "t_frozenRivSla": null,
  "t_gForce": null,
  "t_gForcer": null,
  "t_hairpin": null,
  "t_hClimb": null,
  "t_hClimbr": null,
  "t_indoorKart": null,
  "t_kart": null,
  "t_draglshape": null,
  "t_mnGforce": null,
  "t_mnCityNarrow": null,
  "t_mnCity": null,
  "t_mnCityLong": null,
  "t_mtHairpin": null,
  "c_coupe": null,
  "c_hatchback": null,
  "c_suv": null,
  "c_convertible": null,
  "c_estate": null,
  "c_pickup": null,
  "c_mpv": null,
  "c_van": null,
  "c_dragster": null,
  "c_twister": null,
  "c_speedster": null,
  "c_all-rounder": null,
  "c_tune": null,
  "t_drag100b": null,
  "t_drag100": null,
  "t_drag120": null,
  "t_drag150b": null,
  "t_drag150": null,
  "t_drag170": null,
  "t_drag200": null,
  "t_drag60b": null,
  "t_drag60": null,
  "t_mile1": null,
  "t_mile1r": null,
  "t_mile2": null,
  "t_mile4": null,
  "t_mile4r": null,
  "t_drag100150": null,
  "t_drag30130": null,
  "t_drag50150": null,
  "t_drag75125": null,
  "t_butte": null,
  "t_canyonButte": null,
  "t_dtRoad": null,
  "t_canyonDtRoad": null,
  "t_canyonTour": null,
  "t_cross": null,
  "t_figureEight": null,
  "t_forestRiver": null,
  "t_mnHairpin": null,
  "t_moto": null,
  "t_mtHill": null,
  "t_mtIncline": null,
  "t_mtSlalom": null,
  "t_mtTour": null,
  "t_mtTwisty": null,
  "t_northloop": null,
  "t_northloop1": null,
  "t_northloop2": null,
  "t_northloop3": null,
  "t_northloop4": null,
  "t_northloop5": null,
  "t_oceanSlalom": null,
  "t_oceanCity": null,
  "t_oceanHighway": null,
  "t_oceanLongDrag": null,
  "t_oceanParking": null,
  "t_oceanShortDrag": null,
  "t_rallyMed": null,
  "t_rallySmall": null,
  "t_riverSprint": null,
  "t_runwayDrag": null,
  "t_runwayDragSlalom": null,
  "t_runwayLoop": null,
  "t_serviceRoads": null,
  "t_slalom": null,
  "t_slalomr": null,
  "t_speedbump12km": null,
  "t_speedbump1km": null,
  "t_testBowl": null,
  "t_testBowlr": null,
  "t_tokyoBridge": null,
  "t_tokyoDrag": null,
  "t_tokyoGforce": null,
  "t_tokyoLoop": null,
  "t_tokyoOffRamp": null,
  "t_tokyoOverpass": null,
  "t_tCircuit": null,
  "t_tCircuitr": null,
  "t_tRoad": null,
  "t_waterDrag": null,
  "t_laguna": null,
  "t_lumberTwisty": null,
  "t_desertHill": null,
  "t_desertSmall": null,
  "t_desertBig": null,
  "t_desertRallyDirt": null,
  "t_dockAltCirc": null,
  "t_dockCirc": null,
  "t_dockCity": null,
  "t_dockGforceDrag": null,
  "t_dockKart": null,
  "t_dockUdrag": null,
  "t_lumberForest": null,
  "t_lumberRiver": null,
  "t_drag62": null,
  "t_drag124": null,
  "t_dealsGap1": null,
  "t_dealsGap2": null,
  "t_dealsGap3": null,
  "t_dealsGap4": null,
  "t_dealsGap5": null,
  "t_dealsGap": null,
  "t_dealsGapBack": null,
  "t_nwCircuit": null,
  "t_nwDrag": null,
  "t_nwGforce": null,
  "t_nwLoop": null,
  "t_nwCathedral": null,
  "t_nwTour": null,
  "t_nwSlalom": null,
  "t_miCircuit": null,
  "t_miStreets1": null,
  "t_miStreets2": null,
  "t_miUdrag": null,
  "t_miCause": null,
  "t_miCauseDrag": null,
  "t_miBridge": null,
  "t_miFigure": null,
  "t_miSlalom": null,
  "t_miGforce": null,
  "t_miBeachDrag": null
}