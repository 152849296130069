export default {
  "language": "Polski",
  "m_campaign": "Kampania",
  "m_home": "Strona Główna",
  "m_compare": "Porównaj",
  "m_options": "Opcje",
  "m_about": "O",
  "m_vChanges": "{wersja} Zmiany",
  "m_library": "Biblioteka",
  "m_addLibrary": "Dodaj do Biblioteki",
  "m_copy": "Skopiuj",
  "m_sharableLink": "Link Udostępniający",
  "m_listCars": "Lista aut",
  "m_type": "Rodzaj",
  "m_search": "Wyszukaj",
  "m_searchCar": "Wyszukaj auto",
  "m_searchTracks": "Wyszukaj tory",
  "m_selectTrack": "Wybierz tor",
  "m_contributors": "Kontrybutorzy",
  "m_printBy": "wydruk autorstwa",
  "m_colors": "Kolory",
  "m_display": "Wyświetlanie",
  "m_more3dot": "Więcej...",
  "m_trackset": "Zestaw Torów",
  "m_clear": "Wyczyść",
  "m_clearCars": "Wyczyść listę aut",
  "m_load": "Załaduj",
  "m_loadChart": "Załaduj Wykres",
  "m_memory": "Pamięć",
  "m_image": "Zdjęcie",
  "m_pleaseWait3dot": "Proszę czekać...",
  "m_downloadPng": "Ściągnij w pliku PNG",
  "m_save": "Zapisz",
  "m_cancel": "Anuluj",
  "m_login": "Zaloguj",
  "m_gotoLogin": "Idź na stronę logowania",
  "m_email": "Adres E-mail",
  "m_emailVerification": "Weryfikacja adresu E-mail",
  "m_username": "Nazwa użytkownika",
  "m_password": "Hasło",
  "m_newPassword": "Nowe hasło",
  "m_repeatPassword": "Powtórz hasło",
  "m_repeat": "Powtórz",
  "m_charLeast": "{n} znaków minimum",
  "m_register": "Zarejestruj się",
  "m_forgot": "Zapomniałem",
  "m_allmostDone": "Prawie skończone",
  "m_here": "Tutaj",
  "m_filter": "Filtr | Filtry",
  "m_change": "Zmiana",
  "m_analyze": "Analiza",
  "m_submitReview": "Prześlij do werdyktu",
  "m_saveToLibrary": "Zapisz do biblioteki",
  "m_delete": "Usuń",
  "m_approve": "Zatwierdź",
  "m_round": "Runda | Rundy",
  "m_requirements": "Wymagania",
  "m_empty": "Puste",
  "m_emptyRound": "Pusta runda",
  "m_lockRound": "Zablokuj tą rundę dla mnie",
  "m_unlockRound": "Odblokuj tą rundę",
  "m_findBest": "Znajdź najlepsze",
  "m_other": "Inne",
  "m_win": "wygrana",
  "m_lose": "przegrana",
  "m_draw": "remis",
  "m_notime": "brak czasu",
  "m_select": "wybierz",
  "m_resetSolutions": "Zresetuj tylko rozwiązania",
  "m_resetRound": "Zresetuj rundę",
  "m_includeDownvote": "Zawiera złe czasy",
  "m_showDataFromPast": "Pokaż dane ze starszych wersji",
  "m_showOldTags": "Ukaż przestarzałe kolekcje",
  "m_cgDontRepeatSolution": "nie powtarzaj aut jako propozycji",
  "m_numberRounds": "Liczba rund",
  "m_yes": "Tak",
  "m_no": "Nie",
  "m_new": "Nowy",
  "m_by": "przez",
  "m_checkOut": "Wybadaj",
  "m_templateGuide": "Wskazówki do arkusza",
  "m_title": "Tytuł",
  "m_rqLimit": "Limit RQ",
  "m_points": "Punkty",
  "m_time": "Czas",
  "m_timeToBeat": "Czas do pokonania",
  "m_uploadPrint": "Wgraj wydruk",
  "m_done": "Zrobione",
  "m_sort": "Posortuj",
  "m_send": "Wyślij",
  "m_backHome": "Powrót do strony startowej",
  "m_any": "Jakiekolwiek",
  "m_rarity": "Rzadkość",
  "m_car": "Auto | Auta",
  "m_changed": "Zmienione",
  "m_noRecords": "Brak zapisów",
  "m_showOtherTracks": "Pokaż inne tory",
  "m_showMore": "Pokaż więcej",
  "m_lastContribution": "Ostatni kontrybutorzy",
  "m_nothingFound": "Nic nie znaleziono",
  "m_changedCars": "zmienione auta",
  "m_uploadSuccess": "Udane wysłanie",
  "m_noData": "Brak danych do pokazania",
  "m_noTimesFound": "Brak czasów znalezionych",
  "m_logoutSuccess": "Udane wylogowanie",
  "m_loginSuccess": "Zalogowałeś się",
  "m_deleteSuccess": "Udane usunięcie",
  "m_saveSuccess": "Udany zapis",
  "m_approveSuccess": "Udana akceptacja",
  "m_sentReview": "Przesłano do werdyktu",
  "m_language": "Język",
  "p_userSentCgForAnalyse": "Dziękujemy! Twoja runda zostanie przeanalizowana. Dołącz do serwera Discord aby porozmawiać o tym co chcesz.",
  "p_emptyRoundForUser": "Ta runda nie jest jeszcze zrobiona. Możesz pomóc tworząc ją, a następnie, przesłanie do werdyktu.",
  "p_emptyRound2": "Ta runda nie jest jeszcze zrobiona. Jeżeli jesteś na tej rundzie, możesz pomóc w zawarciu jej.",
  "p_emptyRoundLogin": "Zaloguj się aby zacząć tworzyć tą rundę albo skontaktuj się z moderatorem na naszym serwerze discord:",
  "p_lockRoundPhrase": "Zablokuj tą rundę dla siebie żeby zacząć ją robić.",
  "p_modDoingRound": "{mod} robi tą rundę",
  "p_patronsOnly": "Ta funkcja jest dostępna tylko dla patronów stopnia {tier}",
  "p_bestOfDescription": "Zwraca to listę najlepszych aut dla danego toru i filtra. Przykładowo:",
  "p_chartsDescription": "To umieszcza całą bazę danych o danych do arkusza. Dla demonstracji:",
  "p_templateWarning": "Upewnij się że twój szablon jest przydatny dla społeczności albo nie zostanie zaakceptowany.",
  "p_about1": "Ten projekt został zrobiony przez TiagoXavi i nie jest powiązany z Hutch Games Ltd.",
  "p_about2": "Jakikolwiek problem lub sugestię, proszę dołącz na serwer Discord lub wyślij e-mail (mighty.boy@topdrivesrecords.com).",
  "p_about3": "TDR jest darmowe dla każdego. Możesz wspierać projekt aby uaktualniać go i otrzymywać nowe funkcje!",
  "p_resetPassHelper": "Wpisz swój adres e-mail aby otrzymać link do zresetowania hasła",
  "p_resetPassIfExist": "Jeżeli istneje użytkownik z zarejestrowanym takim e-mailem, link z resetowaniem hasła zostanie wysłany.",
  "p_confirmationWillSent": "E-mail z potwierdzeniem aktywacji konta zostanie wysłany",
  "p_checkYourEmailBox": "Sprawdź swoją skrzynkę mailową",
  "p_spamToo": "Folder ze Spamem też!",
  "p_somethingWrong": "Coś poszło nie tak",
  "c_topSpeed": "Prędkość maksymalna",
  "c_handling": "Skręt",
  "c_class": "Rzadkość | Rzadkości",
  "c_year": "Rok | Lata",
  "c_tyre": "Opona | Opony",
  "c_drive": "Napęd | Napędy",
  "c_clearance": "Prześwit | Prześwity",
  "c_country": "Kraj | Kraje",
  "c_brand": "Marka | Marki",
  "c_tag": "Kolekcja | Kolekcje",
  "c_stock": "Domyślny",
  "c_weight": "Waga",
  "c_fuel": "Rodzaj paliwa",
  "c_seats": "Ilość siedzeń",
  "c_enginePos": "Pozycja silnika",
  "c_bodyStyle": "Nadwozie",
  "c_mid": "Średni",
  "c_low": "Niski",
  "c_high": "Wysoki",
  "c_performance": "Wyczynowe",
  "c_standard": "Standardowe",
  "c_off-road": "Terenowe",
  "c_slick": " Śliske",
  "c_saloon": "Sedan",
  "c_roadster": "Roadster",
  "c_coupe": "Coupe",
  "c_hatchback": "Hatchback",
  "c_suv": "SUV",
  "c_convertible": "Kabriolet",
  "c_estate": "Kombi",
  "c_pickup": "Pickup",
  "c_mpv": "Minivan",
  "c_van": "Van",
  "c_petrol": "Benzyna",
  "c_electric": "Elektryczny",
  "c_hybrid": "Hybryda",
  "c_bioethanol": "Bioetanol",
  "c_hydrogen": "Wodór",
  "c_misc": "Różne",
  "c_frontEngine": "Przednie",
  "c_midEngine": "Centralne",
  "c_mid-rearEngine": "Centralno-Tylne",
  "c_mixedEngine": "Mieszane",
  "c_rearEngine": "Tylne",
  "g_joinDiscord": "Dołącz do serwera Discord TDR aby porozmawiać  o usprawnieniu twojego arkusza lub dlaczego twój szablon został odrzucony.",
  "g_rule0": "Twój szablon powinien mieć kontekst dla aut żeby mieć kontekst. Na przykład, takie same kolekcje, taki sam kraj, takie same opony, taka sama marka, taka sama rzadkość... Albo dowolny kontekst tak jak Hutch robi jako wymagania dla eventów/challengów.",
  "g_rule1": "Powinieneś umieścić wszystkie możliwe auta dla danego kontekstu albo przynajmniej spróbuj dodać je wszystkie. Nie umieszczaj tylko aut które posiadasz.",
  "g_rule2": "Szablon powinien porównywać auta o podobnych danych. Nie ma sensu porównywania auta z napędem na tył z wyczynowymi oponami z autem z napędem na cztery koła z oponami all-surface na suchych asfaltowych torach.",
  "g_rule3": "Jedynie mieszaj asfaltowe tory z terenowymi torami kiedy jest do tego powód.",
  "g_rule4": "Nie musisz umieszczać wszystkich torów będących w grze. Aplikacja będzie miała problemy z wydajnością.",
  "g_rule5": "Spróbuj posortować tory przez typy poprzez zgrupowanie tracków dragowych oraz o takiej samej powietrzni torów.",
  "g_rule6": "Spróbuj posortować auta poprzez RQ (wyższe na początku) przez osiągi na poszczególnych torach.",
  "g_rule7": "Szablon nie musi mieć wszystkich czasów wpisanych, ale możesz je wypełnić to co możesz przed przesłaniem.",
  "g_rule8": "Użyj odpowiedni tytuł dla swojego arkusza.",
  "g_rule9": "Zobacz czy już nie istnieje arkusz o takiej samej tematyce co twój.",
  "m_charts": "Wykresy",
  "m_event": "Wydarzenie",
  "m_events": "Wydarzenia",
  "m_eventName": " Nazwa wydarzenia",
  "m_createNewCg": "Stwórz nowe Wyzwanie",
  "m_createNewEvent": "Stwórz nowe Wydarzenia",
  "m_city": "Miejskie",
  "m_twisty": "Kręte",
  "m_logout": "Wyloguj",
  "m_resetSavedHand": "Zresetuj do wstępnego  rozwiązania",
  "m_showCustomTunes": "Pokaż Własne Tuningi",
  "m_kingForceVertical": "Zmień wyświetlanie na Pionowe",
  "m_showBigCards": "Pokazuj duże Karty",
  "m_chartHideOutOfFilter": "Zakryj samochody poza Filtrem",
  "m_race": " Wyścig",
  "m_useTrackList": " Użyj listy Torów",
  "m_modShowSuspectTimes": "Zakryj Czasy Podejrzane",
  "m_challenges": "Wyzwania",
  "p_beforeLeave": "Jesteś pewny, że chcesz wyjść?",
  "p_eventsKingDescription": "Możesz zobaczyć tylko 1 samochód na daną rzadkość. Oto jak działa pełna lista:\n",
  "p_eventsKingLogin": "Zaloguj się, aby zobaczyć najlepszy samochód dla każdej rzadkości.",
  "s_dry": "Suchy",
  "s_aspht": "Asfalt",
  "s_wet": "Mokry",
  "s_dirt": " Błoto",
  "s_gravel": "Żwir",
  "s_ice": "Lód",
  "s_sand": "Piasek",
  "s_snow": "Śnieg",
  "s_grass": "Trawa",
  "c_prizeCar": " Samochód tylko z Nagród",
  "c_prize cars": " Samochody tylko z Nagród",
  "c_non-prize cars": "Samochody nie tylko z Nagród",
  "c_all-surface": "Wszelko-Powierzchniowe",
  "t_airplaneHangars": null,
  "t_airplaneSlalom": null,
  "t_smallRoad": null,
  "t_butte": null,
  "t_canyonLookout": null,
  "t_canyonTour": null,
  "t_carPark": null,
  "c_tune": "Tuning",
  "m_challengeName": null,
  "m_drag": null,
  "m_meta": null,
  "c_dragster": null,
  "c_twister": null,
  "c_speedster": null,
  "c_all-rounder": null,
  "c_diesel": null,
  "t_drag100b": null,
  "t_drag100": null,
  "t_drag120": null,
  "t_drag150b": null,
  "t_drag150": null,
  "t_drag170": null,
  "t_drag200": null,
  "t_drag60b": null,
  "t_drag60": null,
  "t_mile1": null,
  "t_mile1r": null,
  "t_mile2": null,
  "t_mile4": null,
  "t_mile4r": null,
  "t_drag100150": null,
  "t_drag30130": null,
  "t_drag50150": null,
  "t_drag75125": null,
  "t_townSlalom": null,
  "t_townSprint": null,
  "t_csMed": null,
  "t_csSmall": null,
  "t_townTour": null,
  "t_cross": null,
  "t_canyonDtRoad": null,
  "t_fast": null,
  "t_fastr": null,
  "t_fieldSlalom": null,
  "t_figureEight": null,
  "t_forestRiver": null,
  "t_forest": null,
  "t_forestSlalom": null,
  "t_frozenLake": null,
  "t_frozenRivSla": null,
  "t_gForce": null,
  "t_gForcer": null,
  "t_hairpin": null,
  "t_hClimb": null,
  "t_hClimbr": null,
  "t_indoorKart": null,
  "t_kart": null,
  "t_draglshape": null,
  "t_mnGforce": null,
  "t_mnHairpin": null,
  "t_mnCityNarrow": null,
  "t_mnCity": null,
  "t_mnCityLong": null,
  "t_moto": null,
  "t_mtHairpin": null,
  "t_mtHill": null,
  "t_mtIncline": null,
  "t_mtSlalom": null,
  "t_mtTour": null,
  "t_mtTwisty": null,
  "t_northloop": null,
  "t_northloop1": null,
  "t_northloop2": null,
  "t_northloop3": null,
  "t_northloop4": null,
  "t_northloop5": null,
  "t_oceanSlalom": null,
  "t_oceanCity": null,
  "t_oceanHighway": null,
  "t_oceanLongDrag": null,
  "t_oceanParking": null,
  "t_oceanShortDrag": null,
  "t_rallyMed": null,
  "t_rallySmall": null,
  "t_riverSprint": null,
  "t_runwayDrag": null,
  "t_runwayDragSlalom": null,
  "t_runwayLoop": null,
  "t_serviceRoads": null,
  "t_slalom": null,
  "t_slalomr": null,
  "t_speedbump12km": null,
  "t_speedbump1km": null,
  "t_testBowl": null,
  "t_testBowlr": null,
  "t_tokyoBridge": null,
  "t_tokyoDrag": null,
  "t_tokyoGforce": null,
  "t_tokyoLoop": null,
  "t_tokyoOffRamp": null,
  "t_tokyoOverpass": null,
  "t_tCircuit": null,
  "t_tCircuitr": null,
  "t_tRoad": null,
  "t_waterDrag": null,
  "p_cgNotThisTime1": "To wyzwanie nie jest tutaj dostępne",
  "p_cgNotThisTime2": "Postanowiliśmy nie umieszczać tego wyzwania w TDR. Zagrajmy w starym stylu, po prostu dla zabawy. Przed TDR wszyscy to robiliśmy, możemy to zrobić ponownie. Może to być zbyt trudne lub ryzykowne, ale tylko to wyzwanie. Pozostałe działają normalnie.",
  "p_cgNotThisTime3": "Dlaczego? Straciliśmy kilka społecznych sposobów szukania pomocy i rozwiązań. Tym razem chcemy, aby gracze pomagali sobie nawzajem, zamiast aby TDR podawał wszystkie rozwiązania. Wzajemna pomoc jest rdzeniem każdej społeczności. Discord, Facebook, Reddit, Youtube... Jest ich wiele. Nie jesteś w żadnej społeczności? Jest zakładka z wieloma z nich, znajdź taką, która Ci się podoba:",
  "p_cgNotThisTime4": "Żeby było jasne, to nie jest paywall. Nawet patroni i moderatorzy nie mogą zobaczyć rund.",
  "p_cgNotThisTime5": "Mam nadzieję, że rozumiesz. Baw się dobrze!"
}