export default {
  "language": "Italiano",
  "m_campaign": "Campagna",
  "m_compare": "Confronta",
  "m_home": "Menù Principale",
  "m_challenges": "Sfide",
  "m_event": "Evento",
  "m_events": "Eventi",
  "m_charts": "Grafici",
  "m_options": "Opzioni",
  "m_about": "Informazioni",
  "m_vChanges": "{version} Cambiamenti",
  "m_library": "Libreria",
  "m_addLibrary": "Aggiungi alla libreria",
  "m_sharableLink": "Link per la condivisione",
  "m_copy": "Copia",
  "m_listCars": "Lista delle auto",
  "m_type": "Genere",
  "m_search": "Cerca",
  "m_searchCar": "Cerca auto",
  "m_searchTracks": "Cerca tracciato",
  "m_selectTrack": "Seleziona tracciato",
  "m_contributors": "Contributi",
  "m_printBy": "realizzato da",
  "m_display": "Visuale",
  "m_colors": "Colori",
  "m_more3dot": "Altro...",
  "m_trackset": "Tracciati",
  "m_clear": "Rimuovi tutto",
  "m_clearCars": "Rimuovi tutte le auto",
  "m_load": "Carica",
  "m_image": "Immagine",
  "m_save": "Salva",
  "m_loadChart": "Scarica immagine",
  "m_memory": "Memoria",
  "m_pleaseWait3dot": "Per favore attendi...",
  "m_downloadPng": "Scarica PNG",
  "m_cancel": "Annulla",
  "m_login": "Accedi",
  "m_gotoLogin": "Vai al Login",
  "m_email": null,
  "m_emailVerification": "Verifica email",
  "m_username": "Nome utente",
  "m_password": null,
  "m_newPassword": "Nuova password",
  "m_repeatPassword": "Conferma password",
  "m_charLeast": "Almeno {n} caratteri",
  "m_register": "Registrati",
  "m_allmostDone": "Quasi fatto",
  "m_filter": "Filtro | Filtri",
  "m_analyze": "Analizza",
  "m_delete": "Cancella",
  "t_csMed": "Strade Cittadine Medie",
  "t_csSmall": "Strade Cittadine Piccole",
  "t_fast": "Circuito Veloce",
  "t_tRoad": "Strada Tortuosa",
  "t_tCircuit": "Circuito a Zig Zag",
  "c_topSpeed": "Velocità massima",
  "c_handling": "Manovrabilità",
  "c_hand": "@:c_handling | @:c_handling",
  "m_repeat": "Conferma",
  "m_forgot": "Dimenticata",
  "m_here": "Qui",
  "m_submitReview": "Invia per la revisione",
  "m_saveToLibrary": "Salva nella libreria",
  "m_approve": "Conferma",
  "m_requirements": "Requisiti",
  "m_empty": "Vuoto",
  "m_win": "vittoria",
  "m_lose": "sconfitta",
  "m_draw": "pareggio",
  "m_notime": "senza tempo",
  "m_select": "seleziona",
  "m_includeDownvote": "Includere tempi disapprovati?",
  "m_showDataFromPast": "Mostra dati di aggiornamenti precedenti",
  "m_showOldTags": "Mostra tag non più in uso",
  "m_challengeName": "Nome della sfida",
  "m_eventName": "Nome dell'evento",
  "m_yes": "Sì",
  "m_no": "No",
  "m_new": "Nuovo",
  "m_createNewCg": "Crea nuova sfida",
  "m_createNewEvent": "Crea nuovo evento",
  "m_timeToBeat": "Tempo da battere",
  "m_time": "Tempo",
  "m_done": "Fatto",
  "m_car": "Auto | Auto",
  "m_lastContribution": "Ultime aggiunte",
  "m_language": "Lingua",
  "p_checkYourEmailBox": "Controlla la tua email per favore",
  "p_confirmationWillSent": "Sarà inviata un'email di conferma",
  "p_somethingWrong": "Qualcosa è andato storto",
  "s_gravel": "Ghiaia",
  "s_ice": "Ghiaccio",
  "s_dirt": "Terra",
  "s_sand": "Sabbia",
  "s_snow": "Neve",
  "s_grass": "Erba",
  "c_year": "Anno | Anni",
  "c_tag": null,
  "c_weight": "Peso",
  "c_seats": "Posti",
  "c_enginePos": "Pos motore",
  "c_prizeCar": "Auto Premio",
  "c_prize cars": "Auto Premio",
  "c_non-prize cars": "Auto Non Premio",
  "c_mid": "Media",
  "c_low": "Bassa",
  "c_high": "Alta",
  "c_performance": "Prestazioni",
  "c_standard": null,
  "c_all-surface": "Ogni superf.",
  "c_off-road": "Fuoristrada",
  "c_slick": "Lisci",
  "c_petrol": "Benzina",
  "c_diesel": null,
  "c_electric": "Elettrica",
  "c_hybrid": "Ibrida",
  "c_hydrogen": "Idrogeno",
  "m_findBest": "Trova le migliori",
  "m_checkOut": "Controlla",
  "m_rqLimit": "RQ limite",
  "t_northloop1": "North Loop Relay 1",
  "t_northloop2": "North Loop Relay 2",
  "t_northloop3": "North Loop Relay 3",
  "t_northloop4": "North Loop Relay 4",
  "t_northloop5": "North Loop Relay 5",
  "t_mtTwisty": "Strada Tortuosa di Montagna",
  "t_mtSlalom": "Slalom di Montagna",
  "t_kart": "Circuito Go-Kart",
  "t_mile2": "Striscia da 1/2 Miglio",
  "t_mile4": "Striscia da 1/4 Miglio",
  "t_mile1": "Striscia da 1 Miglio",
  "t_hairpin": "Strada a Tornanti",
  "t_mtHairpin": "Tornante di Montagna",
  "t_forest": "Strada in Foresta",
  "t_slalom": "Test di Slalom",
  "t_mtTour": "Tour di Montagna",
  "t_gForce": "Test Forza-G",
  "t_mile1r": "Striscia da 1 Miglio (R)",
  "t_drag100": null,
  "t_moto": "Pista da Motocross",
  "t_forestSlalom": "Slalom in Foresta",
  "t_indoorKart": "Go-Kart al Coperto",
  "t_hClimb": "Scalata",
  "t_rallyMed": "Circuito di Rally Medio",
  "t_rallySmall": "Circuito di Rally Piccolo",
  "t_mtIncline": "Strada in Pendenza di Montagna",
  "t_testBowl": "Anello di Prova",
  "t_oceanLongDrag": "Accelerazione Litoraneo Lungo",
  "t_oceanHighway": "Autostrada Litoranea",
  "t_oceanParking": "Parcheggio Litoraneo",
  "t_drag100b": null,
  "t_drag120": null,
  "t_drag150b": null,
  "t_drag150": null,
  "t_drag170": null,
  "t_drag200": null,
  "t_drag60b": null,
  "t_drag60": null,
  "t_carPark": "Parcheggio",
  "t_mnCity": "Strade di Monaco",
  "t_mnHairpin": "Tornante Monaco",
  "t_mnCityNarrow": "Strade Strette Monaco",
  "t_oceanCity": "Strade Cittadine Litoranee",
  "t_mnGforce": "Test Forza-G Monaco",
  "t_canyonTour": "Tour del Canyon",
  "t_oceanShortDrag": "Accelerazione Litoraneo Breve",
  "t_tokyoBridge": "Ponte di Tokyo",
  "t_tokyoGforce": "Test Forza-G Tokyo",
  "c_bodyStyle": "Stile Carrozzeria",
  "c_fuel": "Carburante",
  "c_saloon": "Berlina",
  "c_roadster": null,
  "c_pickup": null,
  "c_van": null,
  "c_suv": null,
  "c_convertible": "Convertibile",
  "c_estate": "Familiare",
  "c_coupe": "Coupé",
  "c_hatchback": "Due Volumi",
  "c_mpv": "Minivan",
  "c_bioethanol": "Bioetanolo",
  "c_misc": "Varie",
  "t_mile4r": "Striscia da 1/4 Miglio (R)",
  "t_drag100150": null,
  "t_drag30130": null,
  "t_drag50150": null,
  "t_drag75125": null,
  "t_fastr": "Circuito Veloce (R)",
  "t_figureEight": "A Forma di Otto",
  "t_gForcer": "Test Forza-G (R)",
  "t_hClimbr": "Scalata (R)",
  "t_slalomr": "Test di Slalom (R)",
  "t_testBowlr": "Anello di Prova (R)",
  "t_tCircuitr": "Circuito a Zig Zag (R)",
  "m_saveSuccess": "Salvataggio completato",
  "s_wet": "Bagnato",
  "s_aspht": "Asfalto",
  "s_dry": "Asciutto",
  "c_class": "Classe | Classi",
  "c_tyre": "Pneumatico | Pneumatici",
  "c_country": "Paese | Paesi",
  "c_brand": "Produttore | Produttori",
  "m_change": "Cambia",
  "m_round": null,
  "m_emptyRound": "Round vuoto",
  "m_lockRound": "Blocca questo round per me",
  "m_unlockRound": "Sblocca questo round",
  "c_frontEngine": "Davanti",
  "c_drive": "Guida | Guida",
  "c_clearance": "Altezza da Terra | Altezza da Terra",
  "c_stock": null,
  "m_by": "di",
  "m_templateGuide": "Linee Guida dei Template",
  "m_points": "Punti",
  "m_city": "Cittadini",
  "m_drag": "Accelerazione",
  "m_numberRounds": "Totale rounds",
  "m_changed": "Cambiata",
  "m_rarity": "Rarità",
  "m_noRecords": "Nessun tempo",
  "m_showOtherTracks": "Mostra altri tracciati",
  "m_showMore": "Mostra altro",
  "m_changedCars": "auto cambiate",
  "m_noTimesFound": "Nessun tempo trovato",
  "m_logoutSuccess": "Disconnessione effettuata",
  "m_loginSuccess": "Hai effettuato l'accesso",
  "m_deleteSuccess": "Cancellazione completata",
  "m_approveSuccess": "Approvazione completata",
  "m_uploadSuccess": "Caricamento completato",
  "m_sentReview": "Inviato per la revisione",
  "p_userSentCgForAnalyse": "Grazie! Il round sarà analizzato. Entra su Discord per discuterne se vuoi.",
  "p_emptyRoundForUser": "Questo round non è ancora stato fatto. Puoi aiutare a crearlo e inviarlo per la revisione.",
  "m_uploadPrint": "Carica immagine",
  "m_sort": "Ordina",
  "m_backHome": "Torna al menù principale",
  "m_other": "Non specificato",
  "m_resetSolutions": "Resetta solo le soluzioni",
  "m_resetRound": "Resetta round",
  "m_any": "Qualsiasi",
  "m_send": "Conferma",
  "m_title": "Titolo",
  "m_twisty": "Handling",
  "m_nothingFound": "Nessun risultato",
  "m_noData": "Nessun risultato",
  "p_emptyRound2": "Questo round non è ancora stato fatto. Se stai affrontando questo round, puoi aiutare a inserirlo.",
  "p_emptyRoundLogin": "Effettua l'accesso per cominciare a modificare questo round o contatta un moderatore sul nostro server Discord:",
  "p_lockRoundPhrase": "Blocca questo round per te stesso per cominciare a modificarlo",
  "p_modDoingRound": "{mod} sta creando questo round",
  "p_patronsOnly": "Questa funzione è disponibile solo per i patreons (Livello {tier}).",
  "p_bestOfDescription": "Restituisce una lista delle auto migliori per un determinato filtro e tracciato. Per esempio:",
  "p_chartsDescription": "Inserisce tutto i dati di un tracciato in un grafico. Ecco una dimostrazione:",
  "p_templateWarning": "Assicurati che il tuo template sia utile per la comunità o non sarà approvato.",
  "p_about1": "Questo progetto è stato creato da TiagoXavi e non è affiliato a Hutch Games Ltd.",
  "p_about2": "Per qualsiasi problema o suggerimento, per favore unisciti al server Discord o manda un'email (mighty.boy@topdrivesrecords.com)",
  "p_about3": "TDR è gratis per tutti. Puoi supportare il progetto per aiutare a mantenerlo online e ricevere nuove funzioni!",
  "p_resetPassHelper": "Scrivi la tua email per ricevere un link per il reset della tua password",
  "p_resetPassIfExist": "Se esiste un utente associato a quest'email, un link è stato inviato per il reset della password.",
  "p_spamToo": "Controlla anche la casella spam!",
  "g_joinDiscord": "Entra nel server Discord TDR per discutere dei miglioramenti che puoi apportare ai tuoi template o per sapere per quale motivo non sono stati approvati.",
  "g_rule0": "Le auto inserite dovrebbero avere una correlazione perché il tuo template sia utile. Ad esempio lo stesso tag, paese, pneumatici, produttore, rarità... O qualsiasi altra correlazione che Hutch usa come requisito per gli eventi e le sfide.",
  "g_rule1": "Dovresti inserire tutte le auto che hanno la correlazione che intendi usare o almeno provare a farlo. Non inserire solo le auto che possiedi.",
  "g_rule2": "Il template dovrebbe contenere auto che hanno statistiche simili. Non avrebbe senso confrontare un'auto RWD Prestazioni con un'altra 4WD Ogni Superf. su dei tracciati di asfalto asciutto.",
  "g_rule3": "Usa tracciati su strada e fuoristrada solo se c'è una valida ragione per farlo.",
  "g_rule4": "Non è necessario inserire tutti i tracciati disponibili nel gioco. L'app potrebbe risentirne in termini di performance.",
  "g_rule5": "Cerca di ordinare i tracciati per tipo ad esempio raggruppando i tracciati di accelerazione o quelli con la stessa superficie.",
  "g_rule6": "Cerca di ordinare le auto per RQ (dal più alto al più basso) o in base alla prestazione sui tracciati selezionati.",
  "g_rule7": "Il template non deve necessariamente avere tutti i tempi compilati, ma potresti compilare ciò che riesci prima di inviarlo.",
  "g_rule8": "Usa un titolo descrittivo.",
  "g_rule9": "Controlla se esiste già un template con lo stesso scopo del tuo.",
  "c_dragster": null,
  "c_all-rounder": null,
  "t_townSlalom": "Slalom in Città",
  "t_fieldSlalom": "Slalom nei Campi",
  "t_frozenLake": "Lago Ghiacciato",
  "t_frozenRivSla": "Slalom nel Fiume Ghiacciato",
  "t_draglshape": "Accelerazione a L",
  "t_airplaneSlalom": "Slalom tra gli Aerei",
  "t_mnCityLong": "Strade Lunghe Monaco",
  "t_oceanSlalom": "Slalom sulla Spiaggia Litoraneo",
  "t_runwayDrag": "Accelerazione Pista",
  "t_tokyoLoop": "Circuito di Tokyo",
  "t_tokyoOffRamp": "Svincolo di Tokyo",
  "t_tokyoOverpass": "Passaggio Superiore Tokyo",
  "t_waterDrag": "Accelerazione Lungomare",
  "t_tokyoDrag": "Accelerazione Tokyo",
  "t_forestRiver": "Guado del Fiume",
  "t_mtHill": "Scalata di Montagna",
  "t_speedbump12km": "Dossi 1/2km",
  "t_speedbump1km": "Dossi 1km",
  "t_smallRoad": "Strada Secondaria",
  "t_airplaneHangars": "Hangar degli Aeroplani",
  "c_midEngine": "Mezzo",
  "c_mid-rearEngine": "Poster. Centr.",
  "c_mixedEngine": "Misto",
  "c_rearEngine": "Dietro",
  "t_townSprint": "Sprint in Città",
  "t_northloop": "North Loop",
  "t_runwayDragSlalom": "Slalom & Accelerazione Pisya",
  "t_runwayLoop": "Circuito in Pista",
  "t_serviceRoads": "Strade di Servizio",
  "c_tune": "Potenziamento",
  "m_kingForceVertical": "Visione verticale",
  "m_showCustomTunes": "Mostra potenziamenti particolari",
  "m_resetSavedHand": "Torna alla soluzione migliore",
  "m_showBigCards": "Ingrandisci",
  "m_logout": null,
  "c_twister": null,
  "t_townTour": null,
  "t_cross": null,
  "t_riverSprint": null,
  "t_butte": "Collinetta",
  "t_canyonLookout": "Panoramica nel Canyon",
  "t_canyonDtRoad": "Strada Sterrata nel Canyon",
  t_canyonButte: "Collinetta nel Canyon",
  t_lookout: "Panoramica",
  t_dtRoad: "Strada Sterrata",
  "p_cgNotThisTime1": "Questa sfida non è disponibile qui",
  "p_cgNotThisTime2": "Abbiamo deciso di non inserire questa sfida su TDR. Giochiamo nel vecchio stile, solo per divertimento. Prima del TDR, tutti facevamo così, possiamo farlo di nuovo. Forse può essere troppo difficile o rischioso, ma solo questa sfida. Le altre continuano normalmente.",
  "p_cgNotThisTime3": "Perché? Abbiamo perso alcuni modi sociali per trovare aiuto e soluzioni. Questa volta vogliamo vedere i giocatori che si aiutano invece che il TDR che fornisce tutte le soluzioni. Aiutarsi a vicenda è il cuore di ogni comunità. Discord, Facebook, Reddit, Youtube... Ce ne sono tanti. Non sei in nessuna comunità? C'è una scheda con molte di esse, trova quella che ti piace:",
  "p_cgNotThisTime4": "Solo per essere chiari, questo non è un paywall. Nemmeno i sostenitori o i moderatori possono vedere i round.",
  "p_cgNotThisTime5": "Spero che tu capisca. Divertiti!",
}